import { h } from "hyperapp"

export default () => (
  <div>
    <footer class="Footer">
      <p>
        Made with ❤️ using <a href="https://hyperapp.dev/">Hyperapp</a>
      </p>
    </footer>
  </div>
)
